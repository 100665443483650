// IconResolver.js
import React from "react";
import * as Icons from "@mui/icons-material";

const IconResolver = ({ iconName, ...props }) => {
  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    console.error(`Icon "${iconName}" not found`);
    return <div>No Icon Found</div>; // You can return a default icon or an empty element here
  }

  return <IconComponent {...props} />;
};

export default IconResolver;
