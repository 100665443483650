import {
  Box,
  Typography,
  Grid,
  CardMedia,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import IconResolver from "../../../../components/IconResolver";
import SnackbarComponent from "../../../../components/Snackbar";
import { useContextLandingPage } from "./ContextLandingPage";

const ViewFooter = () => {
  console.log("RENDERED ---  VIEW-FOOTER");
  const {
    loadingSecond,
    emailSecond,
    setEmailSecond,
    apiRegisterNewWaitlistMember,
  } = useContextLandingPage();
  return (
    <Box
      sx={{
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "40px",
      }}
    >
      <Typography sx={{ marginTop: "40px", fontSize: "18px" }}>
        Are you ready to
        <span style={{ fontWeight: "800", color: "red" }}> accelerate </span>
        your business?
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "60%",
          padding: "20px",
          textAlign: "center",
          backgroundColor: "#f5f5f5", // Light background color for emphasis
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          margin: "20px",
          "@media (max-width:900px)": {
            marginY: "20px",
            width: "85%",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: "700",
            color: "#333",
            marginBottom: "20px",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          Exciting News!
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            color: "#007BFF",
            marginBottom: "10px",
          }}
        >
          InboXpert is launching in just a few days.
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "400",
            color: "#555",
            marginBottom: "20px",
          }}
        >
          Join our waitlist now to be among the first to know when we go live!
        </Typography>

        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            width: "85%",
            height: "45px",
            borderWidth: "1px",
            borderColor: "black",
            borderStyle: "solid",
            boxShadow: "5px 5px 7px lightgrey",
            "&:hover": {
              boxShadow: "10px 10px 7px lightgrey",
            },
            "@media (max-width: 900px)": {
              width: "100%",
            },
          }}
        >
          <label style={{ height: "45px", width: "75%" }}>
            <input
              style={{
                height: "100%",
                width: "100%",
                borderWidth: "0px",
                flex: 1,
                backgroundColor: "transparent",
                outline: "none",
                fontSize: "14px",
                fontWeight: "600",
                marginLeft: "7px",
                color: "black",
              }}
              value={emailSecond}
              onChange={(e) => {
                setEmailSecond(e.target.value);
              }}
              placeholder="Enter Your Email"
              color="black"
              name="email"
            />
          </label>
          <Box
            onClick={() => {
              apiRegisterNewWaitlistMember({
                email: emailSecond,
                componentNumber: "second",
              });
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "25%",
              minWidth: "60px",
              backgroundColor: "black",
              color: "white",
            }}
          >
            {!loadingSecond ? (
              <Typography
                sx={{
                  fontWeight: "800",
                  textDecoration: "underline",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  "@media (max-width: 900px)": {
                    fontSize: "12px",
                  },
                }}
              >
                Join Waitlist
              </Typography>
            ) : (
              <CircularProgress size={"20px"} sx={{ color: "white" }} />
            )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          //   backgroundColor: "green",
          borderTopWidth: "1px",
          borderTopCoolor: "black",
          borderTopStyle: "solid",
          width: "90%",
          paddingTop: "40px",
          marginTop: "120px",
          alignSelf: "flex-start",
          paddingLeft: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          "@media (max-width:900px)": {
            paddingLeft: "10px",
          },
        }}
      >
        <img
          style={{
            width: "150px",
            height: "50px",
            alignSelf: "flex-start",
            objectFit: "cover",
            // backgroundColor: "green",
            marginBottom: "7px",
          }}
          src="/assets/InboxPert_Main_Logo.png"
        />
        <Typography sx={{ fontSize: "14px", textDecoration: "underline" }}>
          info@inboxpert.com
        </Typography>
        <Typography
          sx={{
            marginTop: "7px",
            fontSize: "14px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          30 N GOULD ST # 41499,SHERIDAN, WYOMING, US
        </Typography>
        {/* <Typography></Typography> */}
      </Box>
    </Box>
  );
};

export default ViewFooter;
