// SnackbarComponent.js
import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, {
  AlertColor,
  AlertProps,
  AlertPropsColorOverrides,
} from "@mui/material/Alert";
interface IParamsShow {
  message?: string | undefined;
  severity?: AlertColor | undefined;
}

export interface RefSnackbar {
  show: (options: IParamsShow) => void;
}

const SnackbarComponent = forwardRef<RefSnackbar>((props, ref) => {
  console.log(" --- RENDERED SNACKBAR ---");
  const AlertRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  useImperativeHandle(ref, () => ({
    show,
  }));

  function show(options: IParamsShow) {
    console.log("SHOW CALLED.");
    if (options?.message) setMessage(options?.message);
    if (options?.severity) setSeverity(options?.severity);
    console.log("MESSAGE = ", message);

    setTimeout(() => {
      // if (message.length > 0) setSnackbarOpen(true);
      setSnackbarOpen(true);
    }, 10);
  }

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={() => {
        setSnackbarOpen(false);
      }}
    >
      <MuiAlert
        elevation={6}
        ref={AlertRef}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        severity={severity}
        variant="filled"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
});

export default SnackbarComponent;
