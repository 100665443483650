import React from "react";
import "./App.css";
import LandingPage from "./pages/LandingPages/LandingPage";
import { Box } from "@mui/material";

function App() {
  return (
    <Box
      className="App"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#EFEFEF",
      }}
    >
      <LandingPage />
    </Box>
  );
}

export default App;
