import { Box, Typography, CircularProgress } from "@mui/material";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import IconResolver from "../../../../components/IconResolver";
import { useContextLandingPage } from "./ContextLandingPage";
import { Features } from "./data";

const Header = () => {
  const {
    email,
    setEmail,
    apiRegisterNewWaitlistMember,
    isSmallDevice,
    loading,
  } = useContextLandingPage();
  console.log("RENDERED ---  VIEW-HEADER");
  const apiCall = async () => {
    apiRegisterNewWaitlistMember({ email, componentNumber: "first" });
  };

  return (
    <Box
      sx={{
        // height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img
        style={{
          width: "240px",
          height: "80px",
          alignSelf: isSmallDevice ? "center" : "flex-start",
          objectFit: "cover",
          //   backgroundColor: "white",
          marginLeft: isSmallDevice ? "0px" : "40px",
          marginTop: isSmallDevice ? "15px" : "0px",
        }}
        src="/assets/InboxPert_Main_Logo.png"
      />
      <Typography
        sx={{
          fontWeight: "550",
          fontSize: "16px",
          marginTop: "50px",
          "@media (max-width: 900px)": {
            paddingX: "30px",
          },
        }}
      >
        Are you exhausted from replying to{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          Repitative Emails
        </span>{" "}
        every day?
      </Typography>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "16px",
          "@media (max-width: 900px)": {
            marginY: "15px",
          },
        }}
      >
        OR
      </Typography>
      <Typography
        sx={{
          fontWeight: "550",
          fontSize: "16px",
          "@media (max-width: 900px)": {
            paddingX: "40px",
          },
        }}
      >
        Are you an individual who{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          spends too much time{" "}
        </span>{" "}
        Planning and Writting an Appealing NewsLetter?
      </Typography>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "16px",
          "@media (max-width: 900px)": {
            marginY: "15px",
          },
        }}
      >
        OR
      </Typography>
      <Typography
        sx={{
          fontWeight: "550",
          fontSize: "16px",
          "@media (max-width: 900px)": {
            paddingX: "40px",
          },
        }}
      >
        Would you like to{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          {" "}
          simplify
        </span>{" "}
        your email marketing process?
      </Typography>

      <Typography
        sx={{
          fontSize: "28px",
          fontWeight: "800",
          marginTop: "45px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center", // Center align text and features
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
        }}
      >
        All-In-One AI Powered Email CRM
      </Typography>
      <Typography
        sx={{
          fontSize: "28px",
          fontWeight: "800",
          marginBottom: "45px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "80%",
          alignItems: "center", // Center align text and features
          "@media (max-width: 900px)": {
            fontSize: "20px",
            marginTop: "30px",
            // lineHeight: "20px",
          },
        }}
      >
        One tool for all of your Needs
        {Features.map((feature, index) => (
          <Box
            key={index}
            sx={{
              display: "inline-flex", // Inline flex to maintain text flow
              alignItems: "center", // Center align items within the feature box
              backgroundColor: feature.backgroundColor,
              paddingX: "15px",
              marginX: "5px",
              paddingY: "5px",
              borderRadius: "40px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "black",
              marginBottom: "5px",
              cursor: "pointer",
              "&:hover": {
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "black",
              },
              "@media (max-width:900px)": {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <IconResolver iconName={feature.iconName} />
            <Typography sx={{ marginLeft: "5px" }}>{feature.title}</Typography>
          </Box>
        ))}
      </Typography>
      <Typography sx={{ marginTop: "40px", fontSize: "18px" }}>
        Join{" "}
        <span style={{ fontWeight: "800", textDecoration: "underline" }}>
          InboXpert Team
        </span>
      </Typography>
      <Typography sx={{ fontSize: "10px", textDecoration: "underline" }}>
        Signup for Early Access!
      </Typography>

      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          width: "50%",
          height: "45px",
          borderWidth: "1px",
          borderColor: "black",
          borderStyle: "solid",
          boxShadow: "5px 5px 7px lightgrey",
          "&:hover": {
            boxShadow: "10px 10px 7px lightgrey",
          },
          "@media (max-width:900px)": {
            width: "85%",
          },
        }}
      >
        <label style={{ height: "45px", width: "75%" }}>
          <input
            style={{
              height: "100%",
              width: "100%",
              borderWidth: "0px",
              flex: 1,
              backgroundColor: "transparent",
              outline: "none",
              fontSize: "14px",
              fontWeight: "600",
              marginLeft: "7px",
              color: "black",
            }}
            value={email}
            placeholder="Enter Your Email"
            onChange={(e) => {
              console.log(e.target.value);
              setEmail(e.target.value);
            }}
            color="black"
            name="email"
          />
        </label>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "25%",
            minWidth: "60px",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => {
            console.log("Email = ", email);
            apiCall();
          }}
        >
          {!loading ? (
            <Typography
              sx={{
                fontWeight: "800",
                textDecoration: "underline",
                cursor: "pointer",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                "@media (max-width: 900px)": {
                  fontSize: "12px",
                },
              }}
            >
              Join Waitlist
            </Typography>
          ) : (
            <CircularProgress size={"20px"} sx={{ color: "white" }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
