import {
  IBodyRegisterNewWaitlistMember,
  IResponseRegisterNewWaitlistMember,
} from "./types";

const Apis = {
  ApiRegisterNewMember: async (
    options: IBodyRegisterNewWaitlistMember
  ): Promise<IResponseRegisterNewWaitlistMember> => {
    return new Promise(async (resolve) => {
      const email = options.email;
      const fetchData = await fetch(
        "https://inboxpert.com/api/waitlist/register-member",
        {
          method: "POST",
          body: JSON.stringify({
            email,
          }),
          headers: {
            "Content-Type": "Application/json",
          },
        }
      );
      const jsonResponse = await fetchData.json();
      resolve(jsonResponse);
    });
  },
};

export default Apis;
