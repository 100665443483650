import {
  Box,
  Typography,
  Grid,
  CardMedia,
  Card,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import IconResolver from "../../../../components/IconResolver";
import SnackbarComponent from "../../../../components/Snackbar";
import { FeatureDetails, useContextLandingPage } from "./";
const FeaturesSection = () => {
  console.log("RENDERED ---  VIEW-FEATURE-SECTION");
  const { isSmallDevice } = useContextLandingPage();
  return (
    <Box
      sx={{
        color: "black",
        padding: "40px",
        "@media (max-width: 900px)": {
          padding: "0px",
          paddingY: "40px",
          // paddingX: "30px",
          // visibility: false,
          // display: "none",
        },
      }}
    >
      {FeatureDetails.map((feature, index) => (
        <Box
          sx={{
            backgroundColor: index % 2 == 0 ? "#F4F4F4" : "#E8E8E8", // Slightly lighter black for cards
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            textAlign: "center",
            height: "320px",
            paddingY: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "@media (max-width: 900px)": {
              padding: "0px",
              // paddingY: "20px",
            },
          }}
        >
          {!(index % 2 == 0) && (
            <Box
              sx={{
                width: "30%",
                "@media (max-width: 900px)": {
                  width: "60%",
                  marginRight: "5px",
                  textAlign: "left",
                },
              }}
            >
              <Typography
                variant={isSmallDevice ? "body1" : "h6"}
                sx={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginRight: "20px",
                }}
              >
                {feature.title}
              </Typography>
              <Typography variant={isSmallDevice ? "body2" : "body1"}>
                {feature.description}
              </Typography>
            </Box>
          )}
          <img
            src={feature.image}
            alt={feature.title}
            style={{
              width: isSmallDevice ? "160px" : "380px",
              height: isSmallDevice ? "120px" : "280px",
              objectFit: "cover",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
          />
          {index % 2 == 0 && (
            <Box
              sx={{
                width: "30%",
                // backgroundColor: "green",
                marginLeft: "20px",
                "@media (max-width: 900px)": {
                  width: "60%",
                  marginLeft: "5px",
                  textAlign: "left",
                },
              }}
            >
              <Typography
                variant={isSmallDevice ? "body1" : "h6"}
                sx={{
                  fontWeight: "700",
                  marginBottom: "10px",
                }}
              >
                {feature.title}
              </Typography>
              <Typography variant={isSmallDevice ? "body2" : "body1"}>
                {feature.description}
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default FeaturesSection;
