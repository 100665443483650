import {
  Box,
  Typography,
  Grid,
  CardMedia,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import IconResolver from "../../../components/IconResolver";
import SnackbarComponent, { RefSnackbar } from "../../../components/Snackbar";
// import ViewFooter from "./components/ViewFooter";
// import Header from "./components/Header";
import {
  ViewFooter,
  ViewHeader,
  ViewFeatureSection,
  ContextLandingPage,
  Apis,
} from "./components";
import { IBodyRegisterNewWaitlistMember } from "./components/types";

const LandingPage = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const refSnackbar = useRef<null | RefSnackbar>(null);
  const showWaitlistMemberRegisteredSnackbar = () => {};
  const [email, setEmail] = useState("");
  const [emailSecond, setEmailSecond] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);

  const apiRegisterNewWaitlistMember = async (
    options: IBodyRegisterNewWaitlistMember
  ) => {
    // refSnackbar.current?.show({
    //   message:
    //     "Thank you for joining our waitlist! Your email has been saved. We'll notify you when Inboxpert goes live.",
    //   // message: data?.message,
    //   severity: "success",
    // });
    // return;

    const mail: string =
      options.componentNumber == "first" ? email : emailSecond;
    const setMail =
      options.componentNumber == "first" ? setEmail : setEmailSecond;
    const setLoader =
      options.componentNumber == "first" ? setLoading : setLoadingSecond;
    console.log("apiRegisterNewWaitlistMember");

    if (mail.length < 5 || !mail.includes("@")) {
      console.log("Component Number = ", options.componentNumber);
      console.log("!mail.includes(@) = ", mail.includes("@"));
      console.log("mail = ", mail);
      refSnackbar.current?.show({
        message: "Pelase Enter a Valid Email Address",
        severity: "error",
      });
      return;
    }
    setLoader(true);
    const { data, error } = await Apis.ApiRegisterNewMember(options);
    console.log("Data = ", data);
    if (data?.message && data?.message?.length > 0) {
      refSnackbar.current?.show({
        // message:
        //   "Thank you for joining our waitlist! Your email has been saved. We'll notify you when Inboxpert goes live.",
        message: data?.message,
        severity: "success",
      });
      setTimeout(() => {
        setMail("");
        setLoader(false);
      }, 500);
    } else {
      setLoader(false);
      refSnackbar.current?.show({
        // message:
        //   "Thank you for joining our waitlist! Your email has been saved. We'll notify you when Inboxpert goes live.",
        message: error,
        severity: "error",
      });
    }
  };

  return (
    <ContextLandingPage.Provider
      value={{
        showWaitlistMemberRegisteredSnackbar,
        apiRegisterNewWaitlistMember,
        email,
        setEmail,
        emailSecond,
        setEmailSecond,
        isSmallDevice,
        loading,
        setLoading,
        loadingSecond,
        setLoadingSecond,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          backgroundColor: "#EFEFEF",
          flexDirection: "column",
          paddingX: "20px",
          "@media (max-width:900px)": {
            paddingX: "5px",
          },
        }}
      >
        <ViewHeader />
        <ViewFeatureSection />
        <ViewFooter />
        <SnackbarComponent ref={refSnackbar} />
      </Box>
    </ContextLandingPage.Provider>
  );
};

export default LandingPage;
