import { createContext, useContext } from "react";
import { TypeContextLandingPage } from "./types";
const ContextLandingPage = createContext<null | TypeContextLandingPage>(null);
const useContextLandingPage = (): TypeContextLandingPage => {
  const context = useContext(ContextLandingPage);
  if (context === undefined || context === null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
export { useContextLandingPage };
export default ContextLandingPage;
