// const featureDetails = [
//     {
//       title: "Knowledge Base",
//       description: "Can create a Knowledge Base. Using which, it will respond accordingly.",
//       image: "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//     {
//       title: "08 Different Moods",
//       description: "Can reply to the customers elegantly in 08 different tones and moods using Advanced Generative AI.",
//       image: "https://images.pexels.com/photos/247885/pexels-photo-247885.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//     {
//       title: "Pipeline Control",
//       description: "Can integrate Approval Step before auto responding.",
//       image: "https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//     {
//       title: "AI Crafting Marketing Mail",
//       description: "Use Many Advanced AI Models to craft the most appealing mail content",
//       image: "https://images.pexels.com/photos/3184298/pexels-photo-3184298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//     {
//       title: "Marketing Campaigns",
//       description: "Can send mail to a large number of recipients in a group",
//       image: "https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//     {
//       title: "Appealing Newsletter",
//       description: "Use Many Advanced AI Models to craft the most appealing mail content",
//       image: "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//     {
//       title: "Groups",
//       description: "Cluster different people with the same backgrounds together for better targeting",
//       image: "https://images.pexels.com/photos/3184296/pexels-photo-3184296.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//     {
//       title: "Followups",
//       description: "Can schedule follow-ups to further enhance engagement.",
//       image: "https://images.pexels.com/photos/3184294/pexels-photo-3184294.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//     {
//       title: "Insights and Analytics",
//       description: "To track the performance and progress, a detailed insight view will help in analyzing things more productively",
//       image: "https://images.pexels.com/photos/3184300/pexels-photo-3184300.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//     },
//   ];

const featureDetails = [
  {
    title: "Dynamic Knowledge Base",
    description:
      "Create a comprehensive knowledge base to provide accurate responses effortlessly.",
    // image:
    //   "https://images.pexels.com/photos/3184328/pexels-photo-3184328.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    image:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "Diverse Communication Tones",
    description:
      "Engage customers with replies in 8 distinct tones and moods using Advanced AI.",
    image:
      "https://images.pexels.com/photos/3184398/pexels-photo-3184398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "Pipeline Management",
    description:
      "Implement approval steps before auto-responding for better control.",
    image:
      "https://images.pexels.com/photos/3184343/pexels-photo-3184343.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "AI-Enhanced Marketing Emails",
    description:
      "Leverage advanced AI models to craft compelling email content.",
    image:
      "https://images.pexels.com/photos/3184354/pexels-photo-3184354.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "Effective Marketing Campaigns",
    description: "Send emails to large groups of recipients efficiently.",
    image:
      "https://images.pexels.com/photos/3184367/pexels-photo-3184367.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "Engaging Newsletters",
    description: "Create eye-catching newsletters using advanced AI models.",
    image:
      "https://images.pexels.com/photos/3184387/pexels-photo-3184387.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "Targeted Grouping",
    description:
      "Cluster individuals with similar backgrounds for focused targeting.",
    image:
      "https://images.pexels.com/photos/3184402/pexels-photo-3184402.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "Insightful Analytics",
    description:
      "Gain detailed insights to track performance and productivity.",
    image:
      "https://images.pexels.com/photos/3184408/pexels-photo-3184408.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "Insights and Analytics",
    description:
      "To track the performance and progress, a detailed insight view will help in analyzing things more productively",
    image:
      "https://images.pexels.com/photos/3184412/pexels-photo-3184412.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
];

const Features = [
  { title: "Campaigns", iconName: "Campaign", backgroundColor: "#FCE4EC" },
  {
    title: "Email Marketing",
    iconName: "TrendingUp",
    backgroundColor: "#E8F5E9",
  },
  {
    title: "Auto Newsletter Generator",
    iconName: "PostAdd",
    backgroundColor: "#FFF9C4",
  },

  {
    title: "Generate Content using Generative AI",
    iconName: "Psychology",
    backgroundColor: "#EDE7F6",
  },
  {
    title: "Auto Mail Responder (Based on Your Provided Knowledge Base)",
    iconName: "Memory",
    backgroundColor: "#E3F2FD",
  },
];

export { featureDetails, Features };
